import { IconButton, Menu, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import MoreVertIcon from '@mui/icons-material/MoreVert';

const CameraMenu: React.FC<{
  items: MediaDeviceInfo[];
  selected: string | null;
  onSelect: (value: string) => void;
}> = ({ items, selected, onSelect }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (value: string) => {
    if (value !== selected) {
      onSelect(value);
    }
    handleClose();
  };

  return (
    <div>
      <IconButton sx={{ color: 'white' }} onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu id='simple-menu' anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {items.map((item, index) => (
          <MenuItem
            key={'camMenu' + index}
            onClick={() => handleSelect(item.deviceId)}
            sx={{
              backgroundColor: item.deviceId === selected ? '#d2d5ef' : 'transparent'
            }}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

export default CameraMenu;

import React from 'react';
import { useDeviceSelectors } from 'react-device-detect';

//components
import AppInstallInstructionButton from 'src/components/AppInstallButton/AppInstallInstructionButton';
import AppInstallInstructionIOS from 'src/components/AppInstallButton/platform/AppInstallInstructionIOS';
import AppInstallInstructionAndroidFirefox from 'src/components/AppInstallButton/platform/AppInstallInstructionAndroidFirefox';
import AppInstallInstructionAndroidOpera from 'src/components/AppInstallButton/platform/AppInstallInstructionAndroidOpera';
import AppInstallButtonPrompt from 'src/components/AppInstallButton/AppInstallButtonPrompt';
import IF from 'src/components/IF';

//hooks
import { useAppSelector } from 'src/hooks/hooks';

const AppInstallButton: React.FC<{ onClick?: () => void }> = () => {
  const [selectors] = useDeviceSelectors(window.navigator.userAgent);
  const pwaInstalled = useAppSelector((state) => state.pwaInstall.pwaInstalled);

  function getComponent(): React.ReactNode {
    if (!selectors.isMobile) {
      return <AppInstallButtonPrompt />;
    }

    const { isAndroid, isFirefox, isOpera, isIOS } = selectors;

    if (isAndroid) {
      if (isFirefox) {
        return (
          <AppInstallInstructionButton instruction={<AppInstallInstructionAndroidFirefox />} />
        );
      }
      if (isOpera) {
        return <AppInstallInstructionButton instruction={<AppInstallInstructionAndroidOpera />} />;
      }
    }

    if (isIOS) {
      return <AppInstallInstructionButton instruction={<AppInstallInstructionIOS />} />;
    }

    return <AppInstallButtonPrompt />;
  }

  return (
    <IF condition={!pwaInstalled && !window.matchMedia('(display-mode: standalone)').matches}>
      {getComponent()}
    </IF>
  );
};

export default AppInstallButton;

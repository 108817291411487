import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppSelector } from 'src/hooks/hooks';

type Browser = 'Chrome' | 'Safari' | 'Firefox' | 'Edge' | 'Unknown';

const getInstructionsByBrowser = (browser: Browser): string[] => {
  switch (browser) {
    case 'Chrome':
      return [
        'Click on the lock icon near the URL address.',
        'Select "Site settings" and go to "Camera".',
        'Choose "Allow" or "Ask".'
      ];
    case 'Safari':
      return [
        'Go to Settings → Safari → Privacy & Security.',
        'Select "Site Permissions" for camera access.',
        'Choose the site and allow camera access.'
      ];
    case 'Firefox':
      return [
        'Click on the lock icon near the URL address.',
        'Go to "Permission settings".',
        'Select "Allow" for the camera or leave it to ask for access.'
      ];
    case 'Edge':
      return [
        'Click on the lock icon near the URL address.',
        'Select "Permissions for this site" and go to "Camera".',
        'Choose "Allow" or "Ask".'
      ];
    default:
      return ['Please check the privacy settings in your browser for camera access.'];
  }
};
interface Props {
  cameraError: string | null;
}
const detectBrowser = (): Browser => {
  const userAgent = navigator.userAgent;
  if (/Chrome/.test(userAgent) && !/Edg/.test(userAgent)) return 'Chrome';
  if (/Safari/.test(userAgent) && !/Chrome/.test(userAgent)) return 'Safari';
  if (/Firefox/.test(userAgent)) return 'Firefox';
  if (/Edg/.test(userAgent)) return 'Edge';
  return 'Unknown';
};

const HelpDialog: React.FC<Props> = ({ cameraError }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [browser, setBrowser] = useState<Browser>('Unknown');
  const [instructions, setInstructions] = useState<string[]>([]);
  const pwaInstalled = useAppSelector((state) => state.pwaInstall.pwaInstalled);
  const [cameraErrorMessage, setCameraErrorMessage] = useState<string | null>(null);
  useEffect(() => {
    const detectedBrowser = detectBrowser();
    setBrowser(detectedBrowser);
    setInstructions(getInstructionsByBrowser(detectedBrowser));
  }, []);

  useEffect(() => {
    if (cameraError === 'NotAllowedError') {
      setCameraErrorMessage('You don’t have access to the camera.');
    } else if (cameraError === 'NotReadableError' || cameraError === 'AbortError') {
      setCameraErrorMessage('The camera is currently in use by another application.');
    } else {
      setCameraErrorMessage('Unable to access the camera.');
    }
  }, [cameraError]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%'
      }}
    >
      <div>
        <Typography variant='h6' color='textSecondary' align='center' sx={{ mb: 2 }}>
          {cameraErrorMessage}
        </Typography>
        {!pwaInstalled ||
          cameraError === 'NotReadableError' ||
          (cameraError === 'AbortError' && (
            <Button variant='contained' color='primary' onClick={handleClickOpen}>
              Camera Access Guide
            </Button>
          ))}
        <Dialog open={open} onClose={handleClose} sx={{ width: '100%' }}>
          <DialogTitle>
            How to Allow Camera Access
            <IconButton
              aria-label='close'
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500]
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent dividers>
            <Typography variant='h6'>Instructions for {browser}</Typography>
            <List>
              {instructions.map((instruction, index) => (
                <ListItem key={index}>
                  <ListItemText primary={instruction} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
        </Dialog>
      </div>
    </Box>
  );
};

export default HelpDialog;

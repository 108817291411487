import React, { useEffect, useState } from 'react';
import { Box, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useAppDispatch } from 'src/hooks/hooks';
import { setPwaInstalled } from 'src/store/slices/promptInstallSlice';
import AppInstallButton from 'src/components/AppInstallButton';

const AppInstallPopup: React.FC<{ installKey: string }> = ({ installKey }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);
  const dispatch = useAppDispatch();

  const checkIfInstalled = async () => {
    if ('getInstalledRelatedApps' in navigator) {
      const apps = await (navigator as any).getInstalledRelatedApps();
      if (apps.length > 0) {
        dispatch(setPwaInstalled(true));
        handleCloseModal();
        return true;
      }
    }
    if (window.matchMedia('(display-mode: standalone)').matches) {
      dispatch(setPwaInstalled(true));
      handleCloseModal();
      return true;
    }
    return false;
  };

  const handleBeforeInstallPrompt = (event: Event) => {
    event.preventDefault();
    setDeferredPrompt(event);
    setModalOpen(true);
  };

  const handleInstallClick = () => {
    if (deferredPrompt) {
      (deferredPrompt as any).prompt();
      (deferredPrompt as any).userChoice.then(() => {
        handleCloseModal();
        setDeferredPrompt(null);
      });
    }
  };

  const handleAppInstalled = () => {
    dispatch(setPwaInstalled(true));
    handleCloseModal();
  };

  useEffect(() => {
    const initialize = async () => {
      const isInstalled = await checkIfInstalled();
      if (!isInstalled && installKey in localStorage) {
        setModalOpen(true);
        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        window.addEventListener('appinstalled', handleAppInstalled);
      }
    };

    initialize().then();

    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
      window.removeEventListener('appinstalled', handleAppInstalled);
    };
  }, []);

  const handleCloseModal = () => {
    setModalOpen(false);
    localStorage.removeItem(installKey);
  };

  return (
    <Modal open={isModalOpen} onClose={handleCloseModal}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          width: 'calc(22vw - 5%)',
          minWidth: 'calc(400px - 5%)',
          borderRadius: 2,
          boxShadow: 24,
          p: 3
        }}
      >
        <IconButton
          onClick={handleCloseModal}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            color: (theme) => theme.palette.grey[500]
          }}
        >
          <CloseIcon />
        </IconButton>
        <Typography variant='h6' component='h2' sx={{ mb: 2 }}>
          Install Guardoe
        </Typography>

        <AppInstallButton onClick={handleInstallClick} />
      </Box>
    </Modal>
  );
};

export default AppInstallPopup;

import React, { useEffect, useState } from 'react';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';

// components
import { Badge, BottomNavigation, BottomNavigationAction, Box, Paper } from '@mui/material';
import AddMerchant from 'src/components/AddMerchant/AddMerchant';
import IF from 'src/components/IF';

// constants
import {
  CART_ROUTES,
  DISCOUNTS_ROUTES,
  ITEMS_ROUTES,
  MERCHANTS_ROUTES,
  USER_ROUTES
} from 'src/shared/consts/Rout.consts';
import { BASE_PATH, GUARDOE_URL } from 'src/config';
import { MERCHANT_TOKEN } from 'src/shared/consts/SearchParameter.consts';

//icons
import StorefrontIcon from '@mui/icons-material/Storefront';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import DiscountIcon from '@mui/icons-material/Discount';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuIcon from '@mui/icons-material/Menu';
import { useAppSelector } from 'src/hooks/hooks';
import { CartItem } from 'src/shared/interfaces/cartItem.interface';
import { isPricePerUnit } from 'src/utils/scripts/cart';
import { getSubDomain } from 'src/utils/scripts/subdomain';
import { getAccessToken } from 'src/utils/scripts';
import { axiosInstance } from 'src/store/axiosConfig';
import { E_STORES_PATH } from 'src/shared/consts/Api.consts';

const menuIconTheme = { stroke: 'inherit', width: 28, height: 28 };

const MainPage = () => {
  const navigate = useNavigate();
  const subdomain = getSubDomain();
  const accessToken = getAccessToken();
  const [navigationValue, setNavigationValue] = useState<string>(getNavigationValue());
  const [search] = useSearchParams();
  const [merchantToken, setMerchantToken] = useState(search.get(MERCHANT_TOKEN));
  const cartItems = useAppSelector((state) => state.cart.items);

  function getQuantity(): number {
    return cartItems.reduce((sum, cartItem) => sum + getItemQuantity(cartItem), 0);
  }
  function getItemQuantity(cartItem: CartItem): number {
    return isPricePerUnit(cartItem.item?.priceType) ? 1 : cartItem.quantity;
  }

  useEffect(() => {
    if (!merchantToken && (navigationValue === '' || navigationValue === '/')) {
      navigate(MERCHANTS_ROUTES.MERCHANTS);
    }
  }, [navigationValue]);

  function getNavigationValue(): string {
    const regex = new RegExp('^(' + BASE_PATH + '[\\w-]+)\\/?.*');
    const match = location.pathname.match(regex);
    if (match) {
      return match[1];
    }
    return location.pathname;
  }

  useEffect(() => {
    if (getSubDomain()) {
      const checkConditionAndRedirect = async () => {
        try {
          const response = await axiosInstance.get(E_STORES_PATH.IS_ACTIVE);
          const shouldRedirect = response.data.isActive;
          if (!shouldRedirect) {
            window.location.href = GUARDOE_URL;
          }
        } catch (error) {
          console.error('Error fetching condition:', error);
        }
      };
      checkConditionAndRedirect().then();
    }
  }, []);

  useEffect(() => {
    setNavigationValue(getNavigationValue());
  }, [location.pathname]);

  return (
    <Box
      sx={{
        width: { xs: '100vw', sm: '22vw' },
        minWidth: { xs: '100vw', sm: '400px' },
        boxShadow: 10
      }}
    >
      <IF condition={!merchantToken}>
        <Box
          position='relative'
          display='flex'
          flexDirection='column'
          justifyContent='space-between'
          height='100dvh'
          sx={{ boxSizing: 'border-box' }}
        >
          <Box height='100%' overflow='hidden'>
            <Outlet />
          </Box>
          <Paper sx={{ width: '100%', height: 'fit-content', zIndex: 1000 }} elevation={4}>
            <BottomNavigation
              className='navbar'
              showLabels
              value={navigationValue}
              onChange={(event, newValue) => {
                setNavigationValue(newValue);
                navigate(newValue);
              }}
              sx={{
                flexShrink: 0,
                '.MuiBottomNavigationAction-root': {
                  minWidth: '70px'
                }
              }}
            >
              <BottomNavigationAction
                label='Stores'
                icon={<StorefrontIcon sx={menuIconTheme} />}
                value={MERCHANTS_ROUTES.MERCHANTS}
              />

              <BottomNavigationAction
                label='Products'
                icon={<ShoppingBasketIcon sx={menuIconTheme} />}
                value={ITEMS_ROUTES.ITEMS}
              />
              {!!subdomain && accessToken && (
                <BottomNavigationAction
                  label='Cart'
                  icon={
                    <Badge color='primary' badgeContent={getQuantity()}>
                      <ShoppingCartIcon sx={menuIconTheme} />
                    </Badge>
                  }
                  value={CART_ROUTES.CART}
                />
              )}

              <BottomNavigationAction
                label='Discounts'
                icon={<DiscountIcon sx={menuIconTheme} />}
                value={DISCOUNTS_ROUTES.DISCOUNTS}
              />
              <BottomNavigationAction
                label='Menu'
                icon={<MenuIcon sx={menuIconTheme} />}
                value={USER_ROUTES.MENU}
              />
            </BottomNavigation>
          </Paper>
        </Box>
      </IF>
      <AddMerchant
        onFinished={() => {
          setMerchantToken(null);
        }}
      />
    </Box>
  );
};

export default MainPage;

import React, { useEffect, useState } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import styles from 'src/components/SearchBar/BarcodeScanner/styles.module.css';
import { useDeviceSelectors } from 'react-device-detect';
import { Html5Qrcode } from 'html5-qrcode';
import CameraMenu from 'src/components/SearchBar/BarcodeScanner/CameraMenu';
import HelpDialog from 'src/components/SearchBar/BarcodeScanner/HelpPopup';

interface Props {
  onResult: (result: string) => void;
}

const BarcodeScanner: React.FC<Props> = ({ onResult }) => {
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string | null>(null);
  const [result, setResult] = useState('');
  const [html5QrCode, setHtml5QrCode] = useState<Html5Qrcode | null>(null);
  const [deviceSelectors] = useDeviceSelectors(window.navigator.userAgent);
  const [permission, setPermission] = useState<boolean | null>(null);
  const [showSelect, setShowSelect] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorCamera, setErrorCamera] = useState<string | null>(null);

  useEffect(() => {
    checkPermissions().then(() => {
      if (!permission) {
        setLoading(false);
        return;
      }
      const currentHtml5QrCode = new Html5Qrcode('reader');
      setHtml5QrCode(currentHtml5QrCode);
      getDevices().then();
      startScanner(currentHtml5QrCode);
    });
  }, [permission]);

  useEffect(() => {
    return () => {
      stopScanner(html5QrCode);
    };
  }, [html5QrCode]);

  useEffect(() => {
    if (devices.length > 0) {
      setSelectedDeviceId(devices[devices.length > 1 ? 1 : 0].deviceId);
      setShowSelect(!!permission && devices.length > 1);
    }
  }, [permission, devices]);

  useEffect(() => {
    if (selectedDeviceId != null) {
      selectDevice(selectedDeviceId);
    }
  }, [selectedDeviceId]);

  const checkPermissions = async () => {
    try {
      const status = await navigator.permissions.query({ name: 'camera' as PermissionName });
      if (status.state === 'granted') {
        setPermission(true);
      } else if (status.state === 'prompt') {
        await navigator.mediaDevices.getUserMedia({ video: true });
        setPermission(true);
      } else {
        setPermission(false);
        setErrorCamera('Access to the camera is denied. Please enable camera permissions.');
      }
    } catch (error) {
      console.error('Error checking permissions:', error);
      setPermission(false);
      setErrorCamera('Unable to check camera permissions. Please enable camera access manually.');
    }
  };

  const getDevices = async () => {
    try {
      if (deviceSelectors.isIOS) {
        await navigator.mediaDevices.getUserMedia({ video: true });
      }
      const availableDevices = await navigator.mediaDevices.enumerateDevices();
      const availableVideoDevices = availableDevices.filter(
        (device) => device.kind === 'videoinput'
      );
      if (availableVideoDevices.length !== 0) {
        setDevices(availableVideoDevices);
      }
    } catch (exception) {
      console.log('Failed to find cameras.');
    }
  };

  const startScanner = (currentHtml5QrCode: Html5Qrcode | null, deviceId: string | null = null) => {
    const config = {
      fps: 10,
      qrbox: { width: 300, height: 200 },
      experimentalFeatures: {
        useBarCodeDetectorIfSupported: true
      }
    };

    if (!currentHtml5QrCode) {
      currentHtml5QrCode = new Html5Qrcode('reader');
      setHtml5QrCode(currentHtml5QrCode);
    }

    setLoading(true);
    currentHtml5QrCode
      .start(
        deviceId ?? { facingMode: 'environment' },
        config,
        (decodedText) => {
          stopScanner(currentHtml5QrCode);
          setResult(decodedText);
          onResult(decodedText);
          setLoading(false);
        },
        () => {}
      )
      .then(() => {
        setLoading(false);
        console.log('setLoading false');
      })
      .catch((error) => {
        setPermission(error.includes('Permission denied'));
        setErrorCamera(error);
        console.error(error);
        setLoading(false);
      });
  };

  const selectDevice = (deviceId: string | null = null) => {
    if (html5QrCode) {
      setLoading(true);

      stopScanner(html5QrCode);

      setTimeout(() => {
        startScanner(null, deviceId);
        setLoading(false);
      }, 500);
    }
  };

  const stopScanner = (html5QrCode: Html5Qrcode | null): void => {
    if (!!html5QrCode && html5QrCode.getState().valueOf() > 1) {
      html5QrCode.stop().then(() => {
        html5QrCode.clear();
        setHtml5QrCode(null);
        setLoading(false);
      });
    }
  };

  return (
    <Stack sx={{ height: '100%' }}>
      <Box className={styles.readerContainer}>
        <div id='reader' style={{ position: 'relative', width: '100%', height: '100%' }} />
      </Box>
      {loading && <Box className={styles.loadingOverlay}>Loading...</Box>}
      {!loading && !permission && (
        <Box className={styles.helpContainer}>
          <HelpDialog cameraError={errorCamera} />
        </Box>
      )}
      <Typography className={styles.resultText}>{result}</Typography>
      {showSelect && (
        <Box className={styles.cameraMenuWrapper}>
          <CameraMenu items={devices} onSelect={setSelectedDeviceId} selected={selectedDeviceId} />
        </Box>
      )}
    </Stack>
  );
};

export default BarcodeScanner;

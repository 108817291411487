import React, { useEffect, useMemo } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import 'src/App.css';

// mui
import { ThemeProvider } from '@mui/material';

// components
import { Alerts } from 'src/components/Alerts/alerts';
import AppUpdateNotification from 'src/components/AppUpdateNotification';
import AppInstall from 'src/components/AppInstall';
import AppRouters from 'src/routers/AppRouters';

// constants
import { AUTH_ROUTES, MAIN, MERCHANTS_ROUTES } from 'src/shared/consts/Rout.consts';
import { getAccessToken } from 'src/utils/scripts';
import appTheme from 'src/utils/materialConfig';
import { usePullToRefresh } from 'use-pull-to-refresh';
import AppInstallPopup from 'src/components/AppInstall/AppInstallPopup/AppInstallPopup';
import IF from 'src/components/IF';

const MAXIMUM_PULL_LENGTH = 240;
const REFRESH_THRESHOLD = 180;
const installKey = 'install';

function App() {
  const navigate = useNavigate();
  const token = getAccessToken();
  const authPages = useMemo(() => [AUTH_ROUTES.LOGIN, AUTH_ROUTES.REGISTER], []);
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();

  useEffect(() => {
    const install = location.search.includes(installKey);
    if (install) {
      localStorage.setItem(installKey, '');
      setTimeout(() => {
        searchParams.delete(installKey);
        setSearchParams(searchParams, { replace: true });
      }, 500);
    }
  }, []);
  useEffect(() => {
    if (token) {
      if (authPages.includes(location.pathname)) {
        return navigate({ pathname: MAIN, search: searchParams.toString() }, { replace: true });
      }

      if (location.pathname === MAIN || location.pathname === '') {
        return navigate(
          { pathname: MERCHANTS_ROUTES.MERCHANTS, search: searchParams.toString() },
          { replace: true }
        );
      }
    }
  }, [authPages, navigate, location.pathname, token]);

  const handleRefresh = () => {
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  };

  const { isRefreshing, pullPosition } = usePullToRefresh({
    onRefresh: handleRefresh,
    maximumPullLength: MAXIMUM_PULL_LENGTH,
    refreshThreshold: REFRESH_THRESHOLD
  });

  return (
    <ThemeProvider theme={appTheme}>
      <div className='App'>
        <div
          style={{
            top: (isRefreshing ? REFRESH_THRESHOLD : pullPosition) / 3
          }}
          className='refresh-container'
        >
          {pullPosition > 15 || (isRefreshing && <div className='loader'></div>)}
          <Alerts />
          <AppRouters />
          <AppUpdateNotification />
          <AppInstall />
          <IF condition={!!token}>
            <AppInstallPopup installKey={installKey} />
          </IF>
        </div>
      </div>
    </ThemeProvider>
  );
}

export default App;
